@import url('./Global.css');

.about {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/** About our community section **/
.about-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 200px;
    margin-top: 100px;
}

h1.about-section-title {
    font-size: 35px;
    line-height: 40px;
    font-family: articulat-heavy-cf;
}

.about-section .img-and-next-button {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.about-section .about-img {
    width: 100%;
    height: auto;
    margin-top: 10px;
}

.about-section p {
    font-size: 17px;
    line-height: 28px;
    color: black;
    width: 55%;
    margin: 0;
}

.about .next-button {
    display: flex;
    cursor: pointer;
    width: fit-content;
    transition-duration: 200ms;
}

.about .next-button:hover {
    color: var(--synergy-purple);
}

.about .next-button h2 {
    margin: 0;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
}

.about .next-button .next-button-icon {
    height: 18px;
    width: 18px;
    margin-left: 10px;
}

.about-section.right {
    flex-direction: row-reverse;
    text-align: right;
}

.about-section.right .img-and-next-button {
    align-items: flex-end;
}

@media screen and (max-width: 900px) {
    .about .next-button {
        display: none;
    }

    .about-section, .about-section.right  {
        flex-direction: column;
    }

    .about-section.right .img-and-next-button {
        align-items: flex-start;
    }

    .about-section.right {
        text-align: left;
    }

    .about-section .img-and-next-button, .about-section p {
        width: 100%;
    }

    .about-section .img-and-next-button img {
        max-width: 500px;
    }
}

#AboutOurCommunity {
    color: var(--synergy-teal);
    padding-bottom: 20vh;
}

.about-title-and-blurb p {
    font-size: 23px;
    line-height: 36px;
    font-style: italic;
    border-left: 2px solid var(--synergy-teal);
    padding-left: 30px;
    width: 55%;
    margin-bottom: 60px;
}

.about-title-and-blurb h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 40px;
}

/*main blurb*/
#teachers {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

#teachers h1 {
    color: var(--synergy-teal);
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 0;
}

#teachers h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: black;
    width: 100%;
}

#teachers h4 {
    font-size: 60px;
    line-height: 70px;
    padding-top: 30px;
    border-top: 2px solid var(--synergy-teal);
    margin-bottom: 32px;
    color: var(--synergy-teal);
}

#teachers .teacher {
    display: flex;
    padding-top: 4%;
    color: var(--synergy-purple);
}

#teachers .teacher img {
    width: 30%;
    max-width: 300px;
    margin-top: 10px;
    height: fit-content;
    object-fit: cover;
    margin-right: 40px;
}

#teachers .teacher h3 {
    margin: 10px 0 0 0;
    font-size: 36px;
}

#teachers .teacher p {
    font-weight: 500;
    font-size: 20px;
    color: black;
    line-height: 30px;
    max-width: 800px;
}

#teachers .staff-pic {
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    .about-title-and-blurb {
        display: block;
    }
    
    .about-title-and-blurb p { 
        width: 100%;
        margin-left: 0;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 640px) {

    #facilities p, #teachers h2 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
    }

    #facilities .photos-3 {
        flex-direction: column;
    }

    #facilities .photos-4 {
        flex-direction: column-reverse;
    }

    #facilities .photos-3-1, #facilities .photos-4-1 {
        width: 100%;
        margin-bottom: 4%;
    }
    
    #facilities .photos-3-2, #facilities .photos-4-2 {
        width: 100%;
        flex-direction: row;
    }

    #facilities .photos-3-2 img, #facilities .photos-4-2 img {
        width: 48%;
    }

    #teachers .teacher {
        flex-direction: column;
    }
    
    #teachers .teacher img {
        width: 60%;
        max-width: 400px;
        margin-bottom: 20px;
    }

    .about-title-and-blurb h1 {
        font-size: 80px;
        line-height: 100px;
        font-weight: 500;
    }

    #facilities h1, #teachers h1 {
        font-size: 60px;
        line-height: 70px;
        font-weight: 500;
        margin: 0;
    }

    .about p {
        font-size: 20px;
        line-height: 36px;
    }

    #teachers .teacher h3 {
        margin: 20px 0;
        font-size: 36px;
    }
    
    #teachers .teacher p {
        font-size: 18px;
    }

    #teachers h4 {
        font-size: 50px;
        line-height: 65px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 150px;
    }
}