@import url('./Global.css');

.coming-soon {
    min-height: 60vh;
    width: 90%;
    max-width: 1100px;
    margin: 10% auto;
    color: var(--synergy-teal);
    text-align: center;
    box-sizing: border-box;
}

.coming-soon h1 {
    font-size: 80px;
    line-height: 80px;
    font-weight: 500;
    margin: 0;
}

.coming-soon p {
    font-size: 32px;
    line-height: 44px;
    margin: 4vw 0 6vw 0;
}

.coming-soon a {
    text-decoration: none;
    border: none;
    font-size: 20px;
    padding: 15px;
    background-color: var(--synergy-coral);
    border-radius: 20px;
    color: white;
    font-family: articulat-heavy-cf;
    width: fit-content;
    transition-duration: 300ms;
}

.coming-soon a:hover {
    background-color: var(--synergy-teal);
}

@media screen and (max-width: 900px) {
    .coming-soon {
        margin: 25% auto;
    }

    .coming-soon h1 {
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .coming-soon p { 
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 70px;
    }

    .coming-soon a {
        font-size: 15px;
    }
}