@import url('./Global.css');

.faq {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.faq h1 {
    color: var(--synergy-teal);
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
}

.faq .questions-container {
    width: 100%;
}

.faq .question {
    width: 100%;
    background-color: #FAFAFA;
    padding: 10px 12px 8px 12px;
    margin: 20px 0;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
    transition: all .3s;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
}

.faq .question::-webkit-scrollbar {
    display: none;
}

.faq .question.expand {
    max-height: 500px;
    overflow-y: scroll;
}

.faq .q {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 29px;
}

.faq h2 {
    margin: 0;
    font-weight: 600;
    max-width: 90%;
}

.faq .arrow {
    width: 30px;
    height: auto;
    transition: all .3s ease;
}

.faq .arrow.expand {
    transform: rotate(180deg);
}

.faq .a {
    max-height: 0px;
    overflow: hidden;
    transition: all .3s;
}

.faq .expand .a {
    max-height: 300px;
    overflow:initial;
}

.faq p {
    font-size: 18px;
    line-height: 27px;
    margin: 12px 0px 0px 0px;
}

@media screen and (max-width: 640px) {
    .faq h1 {
        margin-top: 0;
    }
}