@import url('./Global.css');

.classtables-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    color: var(--synergy-teal);
}

.classtables-header h1 {
    font-size: 60px;
    line-height: 65px;
    margin: 0 20px 0 0;
    font-weight: 200;
}

.classtables-menus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 420px;
    min-width: 420px;
    margin-bottom: 13px;
}

.classtables-menus form {
    display: flex;
    flex-direction: column;
}

.classtables-menus label {
    margin-bottom: 5px;
    font-weight: 800;
}

.classtables-menus select, .classtables-menus option {
    padding: 6px 4px 4px 4px;
    height: 30px;
    font-size: 16px;
}

.class-table {
    width: 100%;
    height: 60vh;
    border: 1px solid var(--synergy-purple);
}



/** MEDIUM MEDIA SCREEN RESIZE **/
@media screen and (max-width: 1007px) {
    .classtables-header {
        flex-direction: column;
        align-items: center;
    }

    .classtables-header h1 {
        margin: 0 0 30px 0;
        text-align: center;
    }

    .classtables-menus {
        width: 100%;
        max-width: 600px;
        justify-content: space-between;
        text-align: center;
    }
}

/** RESIZE FOR AUTO MOBILE DISPLAY */
@media screen and (max-width: 709px) {
    .class-table {
        display: block;
        max-width: 440px;
        margin: 0 auto;
    }
}

/** TINY MEDIA SCREEN RESIZE **/
@media screen and (max-width: 499px) {
    .classtables-menus {
        flex-direction: column;
    }
    
    .classtables-header h1 {
        margin-bottom: 15px;
    }

    .classtables-menus form {
        margin-bottom: 15px;
    }
}