@import url('./Global.css');

.footer {
    width: 100%;
    background-color: var(--synergy-purple);
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 40px 30px;
    box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.5);
    box-sizing: border-box;
    overflow: hidden;
    bottom: 0;
}

.footer-logo-and-socials {
    height: 100%;
    margin-right: 30px;
}

.footer-logo {
    width: 340px;
    color: white;
    font-weight: 200;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    margin-bottom: 20px;
}

.footer-logo svg {
    height: 80px;
}

.footer-logo p {
    margin: 0px 0px 0px 15px;
    font-size: 28px;
    line-height: 29px;
}

.footer-socials {
    width: 230px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer-socials a {
    height: 32px;
    text-decoration: none;
}

.footer-social-icon {
    height: 32px;
    width: auto;
    color: white;
    transition-duration: 300ms;
}

.footer-social-icon:hover {
    color: var(--synergy-coral);
}

.footer-info h1 {
    margin: 0;
    font-family: articulat-heavy-cf;
    font-size: 32px;
    color: white;
}

.footer-info p, .footer-info a {
    margin: 10px 0 0 0;
    padding-bottom: 2px;
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    border: none;
    color: white;
    transition: 200ms;
    width: fit-content;
    border-bottom: 1px solid var(--synergy-purple);
    border-color: transparent;
}

.footer-info a:hover {
    color: var(--synergy-coral);
    border-bottom: 1px solid var(--synergy-coral);
}

.footer-info {
    padding-left: 15px;
    padding-right: 16px;
    border-left: 1px solid rgba(255,255,255,0.5);
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.mobile-contact-button {
    display: none;
}

@media screen and (max-width: 1006px) {
    .footer {
        height: fit-content;
        padding: 20px;
    }

    .footer-info {
        display: none;
    }
    
    .footer-logo-and-socials {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0;
    }

    .footer-logo {
        border: none;
        width: fit-content;
        height: fit-content;
        padding: 0;
        margin: 0;
    }

    .footer-socials {
        height: fit-content;
        width: 160px;
    }

    .footer-logo p {
        display: none;
    }

    .mobile-contact-button {
        display: block;
        color: black;
        background-color: white;
        padding: 10px 8px 8px 8px;
        border-radius: 10px;
        font-family: inherit;
        font-weight: 800;
        cursor: pointer;
        font-size: 14px;
        transition-duration: 300ms;
    }

    .mobile-contact-button:hover {
        background-color: var(--synergy-coral);
        color: white;
    }

    .footer a {
        text-decoration: none;
        border: 0;
    }

    .footer-logo svg {
        height: 32px;
    }

    .footer-social-icon {
        height: 100%;
        width: auto;
    }
}

@media screen and (max-width: 360px) {
    .footer-socials {
        width: 40vw;
    }

    .footer-socials a {
        height: 8vw;
    }
    
    .footer-social-icon {
        height: 8vw;
    }

    .mobile-contact-button {
        display: none;
    }

    .footer-logo {
        display: none; 
    }

    .footer-logo-and-socials {
        justify-content: center;
    }
}