#facilities {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

#facilities img {
    width: 100%;
    display: block;
}

#facilities h1 {
    color: var(--synergy-teal);
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 0;
}

#facilities h2 {
    color: var(--synergy-teal);
    font-weight: 300;
    margin: 0;
}

#facilities p {
    font-size: 22px;
    line-height: 34px;
    margin: 4% 0;
    width: 100%;
}

#facilities .photos-1, #facilities .photos-3, #facilities .photos-4 {
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
}

#facilities .photos-1-1, #facilities .photos-3-1, #facilities .photos-4-1 {
    width: 66%;
}

#facilities .photos-1-2, #facilities .photos-3-2, #facilities .photos-4-2 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#facilities .photos-1, #facilities .photos-2, #facilities .photos-4 {
    margin-top: 4%;
}

@media screen and (max-width: 640px) {

    #facilities p, #teachers h2 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
    }

    #facilities .photos-3 {
        flex-direction: column;
    }

    #facilities .photos-4 {
        flex-direction: column-reverse;
    }

    #facilities .photos-3-1, #facilities .photos-4-1 {
        width: 100%;
        margin-bottom: 4%;
    }
    
    #facilities .photos-3-2, #facilities .photos-4-2 {
        width: 100%;
        flex-direction: row;
    }

    #facilities .photos-3-2 img, #facilities .photos-4-2 img {
        width: 48%;
    }
}