@import url('./Global.css');

.classes, .classes-descriptions {
    width: 100%;
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
}

.classes-at-synergy, #schedules, #registration {
    height: fit-content;
    padding-bottom: 200px;
}

#schedules, #registration {
    padding-top: 50px;
}

.classes h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    width: fit-content;
    border-bottom: 1px solid var(--synergy-teal);
    padding-bottom: 30px;
    color: var(--synergy-teal);
    margin-bottom: 0;
}

.classes h3 {
    width: 100%;
    font-weight: 500;
    color: var(--synergy-teal);
    font-size: 20px;
    line-height: 32px;
    margin-top: 40px;
}

.pathways-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
}

.classes-pathway {
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.classes-pathway a {
    text-decoration: none;
    color: var(--synergy-purple);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.classes-pathway h2 {
    margin: 0 0 5px 0;
    width: fit-content;
    font-family: articulat-cf;
    font-weight: 900;
    font-size: 50px;
    line-height: 50px;
    transition: 200ms;
}

.classes-pathway p {
    margin: 0;
    font-size: 20px;
    line-height: 28px;
}

.pathways-container p {
    width: 80%;
}

.pathway-icon {
    color:rgb(240,240,240);
    width: 100%;
    height: 80%;
    position: absolute;
    z-index: -1;
    transition-duration: 200ms;
}

.classes-pathway a:hover {
    color: var(--synergy-coral)
}

.classes-pathway a:hover + .pathway-icon {
    height: 100%;
    color:rgb(210,210,210);
}

.classes-descriptions, .class-description-container {
    width: 100%;
}

.classes-descriptions h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 30px;
    color: var(--synergy-teal)
}

.class-description-container img {
    width: 48%;
    height: 100%;
    object-fit:cover;
    display: block;
    margin-top: 10px;
}

.class-description-container .header {
    color: var(--synergy-purple);
    width: 100%;
    border-top: 2px solid var(--synergy-purple);
}

.class-description-container .header .blurb {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    height: fit-content;
}

.class-description-container .header h2 {
    font-weight: 800;
    font-size: 50px;
    margin: 30px 0 20px 0;
}

.class-description-container .header p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    width: 48%;
    margin: 0;
}

.class-description-container .description {
    color: var(--synergy-coral);
    width: 100%;
    border-top: 2px solid var(--synergy-coral);
    padding-top: 30px;
    margin-bottom: 30px;
}

.class-description-container .title-and-age {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.class-description-container .title-and-age h3 {
    margin: 0 20px 0 0;
    font-weight: 800;
    font-size: 30px;
    line-height: 35px;
    max-width: 400px;
}

.class-description-container .title-and-age h4 {
    margin: 0;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    max-width: 200px;
    text-align: right;
}

.class-description-container .description p {
    font-size: 18px;
    line-height: 28px;
}

/** */
@media screen and (max-width: 900px) {
    .pathway-icon {
        width: auto;
        height: 80%;
        position: absolute;
        right:0;
    }

    .pathways-container {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .classes-pathway {
        width: 100%;
        height: fit-content;
        margin: 20px 0;
    }

    .classes-pathway p {
        font-size: 20px;
        line-height: 28px;
        width: 100%;
        max-width: 300px;
    }

    .classes .container {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 720px) {
    .classes-pathway h2 {
        font-size: 40px;
        line-height: 40px;
    }
    
    .classes h1, .classes-descriptions h1 {
        font-size: 15vw;
        line-height: 15vw;
        border-bottom: 0;
        padding-bottom: 0;
        font-weight: 700;
    }

    .classes h3 {
        font-size: 20px;
        line-height: 30px;
        margin-top: 25px;
    }

    .class-description-container .header .blurb {
        flex-direction: column;
    }
    
    .class-description-container .header img {
        width: 100%;
        margin-bottom: 30px;
    }
    
    .class-description-container .header p {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .class-description-container .title-and-age {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .class-description-container .title-and-age h4 {
        text-align: left;
        max-width: 450px;
        margin-top: 10px;
    }
    
}