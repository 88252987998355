@import './Global.css';

:root {
    --navbar-bottom-margin: 18px;
    --navbar-bottom-margin-inverse: -18px;
}

header {
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbar {
    display: flex;
    background-color: var(--synergy-beige);
    height: var(--navbar-height);
    width: 100%;
    top: 0;
    padding: var(--navbar-bottom-margin) 30px 0px var(--navbar-bottom-margin);
    box-shadow: 0px 2px 10px 6px rgba(0,0,0,0.5);
    justify-content: space-between;
    box-sizing: border-box;
}

.navbar a {
    text-decoration: inherit;
    color: inherit;
    transition-duration: 200ms;
    display: flex;
    height: 100%;
}

.navbar svg {
    height: 45px;
    width: 30px;
}

.navbar-logo-and-title {
    margin-bottom: var(--navbar-bottom-margin);
}

.navbar-title {
    font-size: 16px;
    line-height: 16px;
    height: 100%;
    width: 120px;
    color: var(--synergy-teal);
    margin: 0px 0px 0px 10px;
    font-weight: 300;
}

.navbar nav {
    display: flex;
    width: 100%;
    min-width: 450px;
    max-width: 600px;
}

.navbar-menus {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    list-style-type: none;
    margin: 0px;
    font-weight: 400;
}

.navbar-header-item a {
    padding-bottom: var(--navbar-bottom-margin);
}

.menu-link {
    position: relative;
    color: var(--synergy-teal);
    text-decoration: none;
    box-sizing: border-box;
}

.menu-link:hover {
    color: var(--synergy-purple);
}

.navbar-header-item a.active {
    font-weight: 800;
}

.navbar-dropdown a.active {
    font-weight: 400;
}

.navbar-dropdown {
    position: absolute;
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0;
    list-style: none;
    background-color: var(--synergy-beige);
    font-weight: 400;
    display: none;
}

.navbar-dropdown.last-header {
    right: 0px;
}

.navbar-dropdown.show {
    display: block;
}


.navbar-dropdown-item {
    transition-duration: 200ms;
}

.navbar-dropdown-item a{
    padding: 10px 10px 10px 10px;
}

.navbar-dropdown-item:hover {
    background-color: rgb(200,200,200);
}

.navbar-dropdown a {
    text-decoration: none;
    color: var(--synergy-purple);
}

.navbar-dropdown a.active {
    border-bottom: 0px;
}

.navbar-dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 0px;
}

/** MOBILE MENU */

.mobile-hamburger {
    height: 44px;
    width: auto;
    cursor: pointer;
}

.mobile-menu-open-close {
    display: none;
    color: var(--synergy-teal);
}

.mobile-menu {
    display: none;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--synergy-beige);
    z-index: 11;
    box-sizing: border-box;
    transition-duration: 300ms;
    left: 100%;
    top: var(--navbar-height);
    height: 410px;
}

.mobile-menu.show {
    left: 50%;
}

.mobile-menu-links {
    position: relative;
    overflow-x: hidden;
    width: 50vw;
    height: fit-content;
    box-sizing: border-box;
    transition-duration: 300ms;
    padding: 0 30px 0 0;
    margin: 0;
}

.mobile-menu ul {
    list-style-type: none;
    padding: 0;
    box-sizing: border-box;
}

.mobile-menu li {
    color: var(--synergy-teal);
    font-size: 20px;
    transition-duration: 100ms;
}

.mobile-dropdown li {
    font-size: 17px;
}

.mobile-menu a, .mobile-menu .dropdown-item {
    color: inherit;
    font-weight: 300;
    text-decoration: none;
    height: 100%;
    display: block;
    padding: 10px 10px;
    cursor: pointer;
    transition-duration: 250ms;
}

.mobile-menu a:hover, .mobile-menu .dropdown-item:hover {
    color: var(--synergy-purple);
    font-weight: 800;
}

.mobile-menu .dropdown-item {
    display: flex;
    justify-content: space-between;
}

.mobile-dropdown {
    display: none;
}

.mobile-dropdown.show {
    display: block;
}

/** list within a list (dropdown) */
.mobile-menu li ul {
    padding-left: 15px;
}

.mobile-menu .arrow {
    height: 100%;
    width: auto;
    transition-duration: 250ms;
}

.mobile-menu .arrow.open {
    transform: rotate(180deg);
}

@media screen and (max-width: 700px) {
    .navbar nav {
        display: none;
    }

    .mobile-menu-open-close {
        display: block;
    }
    
    .mobile-menu {
        display: block;
    }
}