.program {
    width: 100%;
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
}

.program h1 {
    color: var(--synergy-teal);
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 0;
}

.program h2 {
    color: var(--synergy-teal);
    font-weight: 300;
    margin: 0;
}

.program h3 {
    margin: 0 0 10px 0;
    font-weight: 800;
    font-size: 20px;
}

.program-blurb {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    gap: 20px;
}

.program-blurb img.about-img {
    width: 400px;
}

.program-blurb img.large-img {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .program-blurb {
        flex-direction: column;
    }

    .program-blurb img.large-img {
        width: 100%;
    }
}

.program-blurb p {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}

.program a {
    font-family: inherit;
    font-weight: 800;
    color: white;
    background-color: var(--synergy-purple);
    border-radius: 5px;
    border: none;
    padding: 10px;
    width: fit-content;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 200ms;
    box-sizing: border-box;
    display: block;
}

.program a:hover {
    background-color: var(--synergy-coral);
}

.program .links {
    display: flex;
    gap: 10px;
}