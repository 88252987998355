@import url('./Global.css');

#policies {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

#policies h1 {
    color: var(--synergy-teal);
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
}

#policies p {
    font-size: 20px;
    line-height: 36px;
    margin-top: 0;
}

#policies h2 {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 10px 0;
}

#policies ul {
    font-size: 20px;
    line-height: 36px;
}

@media screen and (max-width: 640px) {
    #policies h1 {
        margin-top: 0;
        font-size: 40px;
        line-height: 50px;
    }

    #policies p, #policies ul {
        font-size: 16px;
        line-height: 28px;
    }
    
    #policies h2 {
        font-size: 20px;
        line-height: 32px;
    }
    
}