@import './Global.css';

.bg {
    /* Modified as a test to see if this is simply a relative position issue
    this is working locally but not when pushed up to robgroff.com 
    Src
        --> Components
            --> Pages
                Home.jsx
        --> Documents
        --> Images
        --> Styles
            Home.css
        

    background-image: url("../Images/studio-wide.webp"); 
    background-image: url("%PUBLIC_URL%/studio-wide-testing.webp");*/
    background-image: url("../Images/studio-wide.jpg"); 
    position: fixed;

    /* Extend Slightly off-screen for blur effect */
    height: 95%;
    width: 105%;

    /* Reposition */
    top: 60px;

    /* Center and scale the image nicely */
    background-repeat: no-repeat;
    background-size: cover;

    /* Blur the image */
    filter: blur(4px);
    -webkit-filter: blur(4px);

    /* Place below everything */
    z-index: -10;
}

.home-title {
    height: 65vh;
    min-height: 420px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: 3px 3px 10px rgba(0,0,0,0.5);
}

.home-title p {
    margin: 0;
    font-weight: 200;
    font-size: 38px;
    line-height: 38px;
}

.home-title h1 {
    margin: 0;
    font-weight: 900;
    font-family: articulat-heavy-cf;
    font-size: 135px;
    line-height: 160px;
}

.home-title h2 {
    margin: 0;
    font-weight: 700;
    font-size: 42px;
    line-height: 42px;
    margin-top: 20px;
}

.home-alert-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}

.home-alert-container.hide, .events-alert.hide {
    display: none
}

.home-alert {
    margin-top: 20px;
    background-color: rgba(0,128,128,0.75);
}

.events-alert, .static-alert {
    margin-top: 105px;
    background-color: rgba(194,125,125,0.75);
}

.home-alert, .events-alert, .static-alert {
    position: absolute;
    top: var(--navbar-height);
    right: -50vw;
    display: flex;
    align-items: center;
    color: white;
    transition-duration: 200ms;
}

.static-alert {
    right: 0vw;
    cursor: pointer;
    text-decoration: none; 
}

.home-alert:hover, .events-alert:hover {
    right: 0px;
}

.home-alert .icon-and-header, .events-alert .icon-and-header, .static-alert .icon-and-header {
    display: flex;
    align-items: center;
    transition-duration: 200ms;
}

.home-alert-icon {
    height: 40px;
    width: auto;
    margin: 0 10px;
}

.home-alert h1, .events-alert h1, .static-alert h1 {
    width: 170px;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    padding-top: 3px;
    padding-right: 16px;
}

.home-alert p, .events-alert p {
    width: 50vw;
    font-size: 16px;
    line-height: 20px;
    margin: 8px 0px;
    border-left: 1px solid white;
    padding-left: 16px;
    box-sizing: border-box;
}

.htlo-video-container {
    width: 100%;
    overflow: hidden;
    background-color: white;
    padding: 100px 0;
}

.htlo-video {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 8% 2%;
}

.htlo-video .text {
    width: 40vw;
    box-sizing: border-box;
}

.htlo-video .text h1 {
    font-size: 4vw;
    line-height: 4vw;
    margin: 0;
}

.htlo-video .text p {
    font-size: 2vw;
    line-height: 3vw;
    font-weight: 300;
    margin-bottom: 0;
}

.htlo-video .video-frame {
    width: 38vw;
    height: 22vw;
}

.home-mia-message {
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    margin-top: 15%;
    background-color: rgba(0,0,0,0.7);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-mia-message.hide {
    display: none;
}

.home-mia-message h1 {
    font-weight: 100;
    font-size: 80px;
    line-height: 80px;
    width: fit-content;
    margin: 0 0 30px 0;
    font-weight: 400;
}

.home-mia-message h3 {
    font-weight: 300;
    font-size: 16px;
    border-top: 2px solid white;
    margin: 0;
    padding-top: 20px;
}

.home-mia-message h3, .home-mia-message p {
    width: 100%;
    max-width: 800px;
}

.home-mia-message p {
    font-size: 20px;
    line-height: 30px;
}

.home-banner {
    background-color: var(--synergy-light-purple);
    height: fit-content;
    padding: 10vw 5vw;
    transition: all .1s;
}

.home-banner.reverse {
    background-color: var(--synergy-light-teal);
}

.home-banner-content {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.reverse .home-banner-content{
    flex-direction: row-reverse;
    text-align: right;
}

.banner-info {
    display: flex;
    flex-direction: column;
    color: var(--synergy-purple);
    width: 45vw;
}

.reverse .banner-info {
    align-items: flex-end;
}

.banner-info h1 {
    font-size: 6vw;
    line-height: 7vw;
    font-weight: 300;
    margin: 0;
    width: fit-content;
}

.banner-info p {
    font-size: 1.7vw;
    line-height: 2.3vw;
    font-weight: 300;
    margin: 1vw 0 2vw 0;
    width: 100%;
}

.home-banner img {
    height: 100%;
    max-width: 30vw;
    object-fit: contain;
}

.home-banner a {
    text-decoration: none;
    border: none;
    font-size: 1.3vw;
    line-height: 1.3vw;
    padding: 1.1vw 1.3vw 0.8vw 1.3vw;
    background-color: var(--synergy-coral);
    border-radius: 15px;
    color: white;
    font-family: articulat-heavy-cf;
    width: fit-content;
    transition-duration: 300ms;
}

.home-banner a:hover {
    background-color: var(--synergy-teal);
}

.home-bottom {
    height: 80vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    color: white;
}

.home-bottom p {
    font-size: 72px;
    line-height: 80px;
    font-weight: 800;
    text-shadow: 3px 3px 10px rgba(0,0,0,0.5);
    padding-left: 5%;
}

/** MEDIUM MEDIA SCREEN RESIZE **/
@media screen and (max-width: 700px) {
    .home-title p {
        font-size: 8vw;
        line-height: 8vw;
    }
    
    .home-title h1 {
        font-size: 21vw;
        line-height: 25vw;
    }
    
    .home-title h2 {
        font-size: 7vw;
        line-height: 7vw;
        margin-top: 1.5vw;
    }
    
    .home-title {
        margin-top: 0;
    }

    .static-alert {
        display: none
    }

    .home-alert-container {
        position:static;
    }
    
    .home-alert {
        position: static;
        margin-top: 0;
        right: 0vw;
        flex-direction: column;
        display: block;
        padding: 20px 15px 20px 5px;
    }

    .home-alert h1 {
        width: 100%;;
        text-transform: uppercase;
        margin: 12px 0;
    }
    
    .home-alert p {
        width: 100%;
        border-left: none;
        box-sizing: border-box;
    }

    .home-mia-message h1 {
        font-size: 12.5vw;
        line-height: 12.5vw;
    }
    
    .home-banner-content {
        flex-direction: column;
    }

    .home-banner-content, .reverse .home-banner-content{
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    
    .banner-info, .reverse .banner-info {
        align-items: center;
        width: 100%;
    }

    .home-banner {
        height: fit-content;
        padding: 14vw 6vw;
    }

    .home-banner img {
        max-width: 80%;
        margin-bottom: 7vw;
        height: auto;
    }
    
    .banner-info h1 {
        font-size: 70px;
        line-height: 70px;
        font-weight: 300;
        margin: 0;
        width: fit-content;
    }
    
    .banner-info p {
        font-size: 24px;
        line-height: 32px;
        font-weight: 200;
        margin: 4vw 0;
        width: 100%;
    }

    .home-banner a {
        margin-top: 10px;
        font-size: 16px;
        line-height: 20px;;
        padding: 12px;
        max-width: 240px;
    }
    
    .htlo-video {
        flex-direction: column;
        padding: 8%;
    }
    
    .htlo-video .text {
        width: 100%;
        margin-left: 0;
        box-sizing: border-box;
    }
    
    .htlo-video .text h1 {
        font-size: 8.5vw;
        line-height: 8.5vw;
        margin-top: 30px;
        width: fit-content;
        text-align: center;
    }
    
    .htlo-video .text p {
        font-size: 24px;
        line-height: 36px;
        margin: 20px 0;
        text-align: center;
    }
    
    .htlo-video .video-frame {
        width: 100%;
        height: 45vw;
    }
}