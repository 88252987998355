:root {
    --synergy-teal: #008080;
    --synergy-light-teal: #E8F5F5;
    --synergy-purple: #5A5D87;
    --synergy-light-purple: #F6F6F6;
    --synergy-beige: #F9F6EE;
    --synergy-coral: #C27D7D;

    --navbar-height: 80px;
}

body {
    font-family: articulat-cf;
}

.container {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    overflow: hidden;
}